import React, { useState, useContext, useEffect, useRef } from "react"
import { AppContext } from "./Context"

import styles from "./main.module.scss"
import headerStyles from "./header.module.scss"
import { cookie } from "../helpers"

const Main = ({ children, location }) => {

  const appState = useContext(AppContext)

  const mainEl = useRef(null)

  const [headerScrollOut, setHeaderScrollOut] = useState(false)

  // Handle header scroll out when reaching last homepage module
  useEffect(() => {
    if (typeof window !== "undefined")
      document.addEventListener("scroll", handleScroll)
    return () =>
      document.removeEventListener("scroll", handleScroll)
  })

  const handleScroll = e => {
    if (location.pathname === "/") {
      const scrollTop = window.scrollY
      const modules = mainEl.current.getElementsByTagName("section")
      const lastModule = modules[modules.length - 1]
      const lastModuleOffsetTop = lastModule.offsetTop

      // if (scrollTop + windowHeight > mainHeight) {
      if (scrollTop > lastModuleOffsetTop) {
        setHeaderScrollOut(true)
      } else {
        setHeaderScrollOut(false)
      }
    }
  }

  // Set userToken in cookie for use in checkout for Algolia conversion event
  if (typeof window !== "undefined")
    cookie.set("userToken", appState.userToken, null, location.hostname.replace("www.",""))

  return(
    <main
      className={`
        ${(location.pathname !== "/") ? styles.mainContainer : headerStyles.homepageContainer}
        ${(appState.navOpen) ? headerStyles.navOpen : ""}
        ${(headerScrollOut) ? headerStyles.headerScrollOut : ""}
        ${(appState.bannerOpen) ? headerStyles.mainHasBanner : ""}
      `}
      ref={mainEl}
    >
      {children}
    </main>
  )
}

export default Main
