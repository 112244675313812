import React  from "react"
import qs from "qs"

import styles from "./loop.module.scss"

const Loop = ({ location }) => {

  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })

  if (typeof sessionStorage !== "undefined" && parsedQuery.loop_return_id) {
    sessionStorage.setItem("tm_loop", `{
      "active": "true",
      "amount": "${parsedQuery.loop_total}",
      "redirect_uri": "${parsedQuery.loop_redirect_uri}",
      "return_id": "${parsedQuery.loop_return_id}"
    }`)
  }

  const tm_loop = (typeof sessionStorage !== "undefined") ?
    JSON.parse(sessionStorage.getItem("tm_loop"))
    : null

  return(
    <>
      {tm_loop && tm_loop.active === "true" &&
        <div className={styles.loopOnstore}>
          <p className={styles.copy}>
            <span className={styles.amount}>${(Number(tm_loop.amount) / 100).toFixed(2)}</span>
            in available credit
          </p>
          <a className={styles.backBtn} href={tm_loop.redirect_uri}>Go Back</a>
        </div>
      }
    </>
  )
}

export default Loop
