import React from "react"

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Heading from "./Heading"
import LinkTM from "./LinkTM"


const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading tag="h2" level="2">{children}</Heading>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading tag="h3" level="3">{children}</Heading>,
    [INLINES.HYPERLINK]: (node, children) => <LinkTM url={node.data.uri} text={children}/>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
  },
}

const RichText = ({ json }) => {

  return(
    <>
      {documentToReactComponents(json, options)}
    </>  
  )
}

export default RichText
