import React, { useContext } from "react"
import axios from "axios"

import cart from "../helpers/cart"

import styles from "./checkoutBtn.module.scss"
import { AppContext } from "./Context"


const CheckoutBtn = ({ showCartCount, className }) => {

  const appState = useContext(AppContext)

  const cartItems = cart.getCart().map((item) => (
    {
      variantId: item.variantId,
      quantity: item.quantity,
    }
  ))

  const createCheckout = () => {
    const hashedVariants = cartItems.map(variant => (
      {
        quantity: variant.quantity,
        variantId: Buffer.from(`gid://shopify/ProductVariant/${variant.variantId}`).toString('base64'),
      }
    ))

    axios({
      url: 'https://shop.tamaramellon.com/api/2021-07/graphql.json',
      method: 'post',
      headers: {
        // 'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
        'X-Shopify-Storefront-Access-Token': "14ddd2b4c87fb6bc9c77e95cf3bed2c5",
      },
      data: {
        query: `fragment UserErrorFragment on UserError {
          field
          message
        }
        fragment CheckoutFragment on Checkout {
          webUrl
        }
        mutation ($input: CheckoutCreateInput!) {
          checkoutCreate(input: $input) {
             userErrors {
              ...UserErrorFragment
            }
            checkout {
              ...CheckoutFragment
            }
          }
        }`,
        variables: {
          input: {
            lineItems: hashedVariants,
            presentmentCurrencyCode: appState.currency,
          }
        },
      },
    }).then(response => {
      const {
        checkoutCreate
      } = response.data.data

      if (checkoutCreate.userErrors.length > 0) {
        // console.log('CHECKOUT ERROR ', data.data.checkoutCreate.userErrors[0].message)
      } else {
        window ? window.location = checkoutCreate.checkout.webUrl : console.log('error')
      }
    })
  }

  const tm_loop = (typeof sessionStorage !== "undefined") ?
    JSON.parse(sessionStorage.getItem("tm_loop"))
    : null

  const createLoopCart = () => {
    if (tm_loop.cart_token)
      // Update cart with Loop
      axios({
        url: `https://api.loopreturns.com/api/v1/cart/${tm_loop.cart_token}`,
        method: "post",
        headers: {
          "content-type": "application/json",
          "x-authorization": "d963096ec50ae7419104a54ce1ac5a56bc84106b", // API key
        },
        data: {
          "cart": cartItems.map(item => item.variantId),
        },
      })
        .then(response => {
          // Send user to Loop
          window ? window.location = `https://tamaramellon.loopreturns.com/#/cart/v2/${tm_loop.cart_token}` : console.log('error')
      })
    else
      // Create cart with Loop
      axios({
        url: "https://api.loopreturns.com/api/v1/cart/",
        method: "post",
        headers: {
          "content-type": "application/json",
          "x-authorization": "d963096ec50ae7419104a54ce1ac5a56bc84106b", // API key
        },
        data: {
          "cart": cartItems.map(item => item.variantId),
        },
      })
        .then(response => {
          tm_loop.cart_token = response.data.token
          sessionStorage.setItem("tm_loop", JSON.stringify(tm_loop))
          // Send user to Loop
          window ? window.location = `https://tamaramellon.loopreturns.com/#/cart/v2/${tm_loop.cart_token}` : console.log("error")
        })
  }

  return (
    <button
      className={`${styles.btn} ${className || ""}`}
      onClick={(tm_loop && tm_loop.active === "true") ? createLoopCart : createCheckout}
    >
      Checkout Now
      {showCartCount &&
        <span className={styles.cartCount}>({appState.cartCount})</span>
      }
    </button>
  )
}


export default CheckoutBtn

