import React, { useContext, useEffect, useState } from "react"

import Heading from "./Heading"
import { AppContext } from "./Context"

import styles from "./chat.module.scss"
import overlayStyles from "./overlay.module.scss"
import loopStyles from "./loop.module.scss"

import { trackEvent } from "../helpers"


const Chat = ({ location }) => {
  const appState = useContext(AppContext)

  const handleChatButton = () => {
    appState.set({chatOpen: true})

    if (window.hasOwnProperty("gtag"))
      trackEvent("Chat Button", "Click", "Sticky")
  }

  const handleLivechat = () => {
    document.querySelector(".LPMcontainer").click()
    appState.set({chatOpen: false, livechatOpen: true})
    trackContact("Livechat")
  }

  const trackContact = channel => {
    if (window.hasOwnProperty("fbq"))
      window.fbq("track", "Contact")

    if (window.hasOwnProperty("gtag")) {
      trackEvent(`Chat ${channel}`, "Click", "Sticky")
      trackEvent("Chat Option", "Click", "Sticky")
    }
  }

  const [showButton, setShowButton] = useState(location.pathname !== "/")

  useEffect(() => {
    setShowButton(location.pathname !== "/")
    if (typeof window !== "undefined" && location.pathname === "/") {
      window.addEventListener("scroll", handleScroll, true)
    }
    return (
      window.removeEventListener("scroll", handleScroll)
    )
  }, [location.pathname])

  const handleScroll = e => {
    (window.location.pathname !== "/") ?
      setShowButton(true)
      : (window.scrollY > 250) ?
      setShowButton(true)
      :
      setShowButton(false)
  }

  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setClient(true)
  }, [])

  return(
    <>
      <button
        className={`${(appState.chatOpen) ? styles.buttonHidden : styles.button} ${loopStyles.chatBtn} ${(location.pathname === "/") ? styles.onHome : ""} ${(showButton) ? "" : styles.hide}`}
        onClick={handleChatButton}
        id="sticky-chat-button"
      >
        {location.pathname.includes("/products/") ?
          `Fit Questions?`
          : location.pathname === "/" ?
            ``
            :
            `Let's Talk`
        }
      </button>
      <div
        className={(appState.chatOpen) ? styles.overlay : ""}
        role="presentation"
        onClick={() => appState.set({chatOpen: false})}
      >
      </div>
      <div className={(appState.chatOpen) ? styles.containerOpen : styles.container}>
        <button
          className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
          onClick={() => appState.set({chatOpen: false})}
          title="Close"
        >Close</button>
        <Heading
          tag="h3"
          level="3"
          className={styles.heading}
        >
          <button onClick={handleLivechat}>
            {location.pathname.includes("/products/") ?
              `Fit Questions?`
              :
              `Let's Talk`
            }
          </button>
        </Heading>
        <ul className={styles.options} key={key}>
          <li key={1} id="livechat-button-control">
            <button
              className={styles.webButton}
              onClick={handleLivechat}
            >
              <span>Livechat</span>
            </button>
          </li>
          {/* Duplicate button for A/B test */}
          <li key={1.5} id="livechat-button-v1" className={styles.livechatButtonV1}>
            <button
              className={styles.webButton}
              onClick={() => {
                handleLivechat()
                setTimeout(() => {
                  if (document.querySelector(".chat-module--buttonMoreWays--2MmjN"))
                    document.querySelector(".lp_maximized").prepend(document.querySelector(".chat-module--buttonMoreWays--2MmjN"))
                }, 800)
              }}
            >
              <span>Livechat</span>
            </button>
          </li>
          {/**/}
          {appState.isMobile && appState.isApple &&
          <li key={2}>
            <a
              href="https://bcrw.apple.com/urn:biz:040ab1a9-29a8-4dc5-b791-3616dd7bfdfa"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.abcButton}
              onClick={() => trackContact("ABC")}
            >
              <span>iMessage</span>
            </a>
          </li>
          }
          {appState.isMobile && !appState.isApple &&
          <li key={3}>
            <a
              href="sms:+13109974390"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.smsButton}
              onClick={() => trackContact("Phone")}
            >
              <span>SMS</span>
            </a>
          </li>
          }
          <li key={4}>
            <a
              href="https://m.me/tamaramellonbrand"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.fbButton}
              onClick={() => trackContact("FB Messenger")}
            >
              <span>Facebook</span>
            </a>
          </li>
          <li key={5}>
            <a
              href="https://wa.me/13109974390"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.whatsappButton}
              onClick={() => trackContact("WhatsApp")}
            >
              <span>WhatsApp</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Chat
