export const toTitleCase = str => {
  return str.replace(
    /\w\S*/g,
    txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

export const stripHtml = str => {
  if (typeof document !== "undefined") {
    var tmp = document.createElement("DIV")
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ""
  }
  return str
}

export const convertMmToInches = mm => {
  const inches = Number(mm) * 0.0393700787
  return Math.max(Math.round(inches * 10) / 10).toFixed(1)
}

export const cookie = {

  get: (key) => {
    const result = (new RegExp("(?:^|; )" + encodeURIComponent(key) + "=([^;]*)")).exec(document.cookie)
    return (result && result[1] && result[1] !== "null") ? decodeURIComponent(result[1]) : null
  },

  set: (key, value, days, domain) => {
    let expires = "";
    if (days) {
      const date = new Date()
      date.setDate(date.getDate() + days)
      expires = "; expires=" + date.toUTCString()
    }
    let domainStr = "";
    if (domain)
      domainStr = "; domain=" + domain
    return document.cookie = key + "=" + (value || "") + expires + "; path=/" + domainStr
  }
}

export const trackEvent = (category, action, label, value) => {
  if (window.hasOwnProperty("gtag"))
    window.gtag("event", action, {
      "event_category": category,
      "event_label": label,
      "event_value": value,
    })
}
