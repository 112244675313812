import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"

import { AppContext } from "./Context"

import Image from "./Image"
import Price from "./Price"
import CheckoutBtn from "./CheckoutBtn"

import { trackEvent } from "../helpers"
import favorites from "../helpers/favorites"
import cart from "../helpers/cart"

import styles from "./minicart.module.scss"
import overlayStyles from "./overlay.module.scss"


const MiniCart = props => {
  const appState = useContext(AppContext)

  const [cartItems, setCartItems] = useState(cart.getCart())

  const closeModal = () => {
    appState.set({miniCartOpen: false})
  }

  const handleOverlayClick = e => {
    if (e.target.className.includes("--overlay--"))
      closeModal()
  }

  const handleCheckoutButton = () => {
    trackEvent("Mini Cart Modal", "Click", "Checkout Button")
    closeModal()
  }

  useEffect(() => {
    if (appState.cartCount === 0)
      closeModal()
    else
      setCartItems(cart.getCart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.cartCount])

  return (
    <>
      <div
        className={`${overlayStyles.overlayLight} ${(appState.miniCartOpen) ? styles.overlayOpen : styles.overlay}`}
        role="presentation"
        onClick={handleOverlayClick}
      />
      <div className={`${overlayStyles.container} ${(appState.miniCartOpen) ? styles.containerOpen : styles.container}`}>
        {cartItems && cartItems.length > 0 ?
          <>
            <button
              className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
              onClick={closeModal}
              title="Close"
            >Close</button>
            <h2 className={styles.heading}>Cart</h2>
            <ul className={styles.cartItems}>
            {cartItems.reverse().map((item, i) => (
              <CartItem
                key={item.variantId}
                item={item}
                setCartItems={setCartItems}
              />
            ))}
            </ul>
            <Link
              to="/cart"
              className={styles.btn}
              onClick={handleCheckoutButton}
            >
              Go to Cart
            </Link>
            <CheckoutBtn
              showCartCount={true}
              className={styles.checkoutBtn}
            />
          </>
        :
          <>
            <h2 className={styles.heading}>Cart</h2>
            <p className={styles.emptyText}>Your cart is empty. Like your closet, it’ll look prettier with some new shoes in it.</p>
            <Link
              to="/collections/all"
              className={styles.shopAllBtn}
              onClick={closeModal}
            >
              Shop All
            </Link>
          </>
        }
      </div>
    </>
  )
}

const CartItem = props => {
  const appState = useContext(AppContext)

  const {
    item,
    setCartItems
  } = props

  const [isFavorite, setIsFavorite] = useState(false)

  const updateCartCount = () => {
    appState.set({
      cartCount: cart.getCartCount(),
    })
  }

  const removeItem = () => {
    cart.removeItem(item)
    setCartItems(cart.getCart())
    updateCartCount()
  }

  const moveItemToFavorites = () => {
    favorites.addItem(item.sku)
    setIsFavorite(true)
    favorites.getListSkus()
      .then(skus => {
        appState.set({
          favoritesSkus: skus,
        })
      })
    removeItem()
  }

  useEffect(() => {
    setIsFavorite(appState.favoritesSkus.includes(item.sku))
  }, [appState, setIsFavorite, item.sku])

  return (
    <li className={styles.cartItem}>
      <Link
        to={`${item.url}${(item.url.includes("?")) ? "&" : "?"}origin=cart`}
        className={styles.imageLink}
      >
        <Image
          image={item.image}
          className={styles.image}
        />
      </Link>
      <div className={styles.details}>
        <h2 className={styles.title}>
          <Link to={item.url}>{item.title.split(" - ")[0]}</Link>
        </h2>

        {item.title !== "Digital Gift Card" &&
          <p className={styles.color}>{item.color} {item.title.split(" - ")[1]}</p>
        }

        {item.size &&
          <p className={styles.size}>{(item.size === "OS") ? item.size : `${item.size - 30} US / ${item.size} EU`}</p>
        }

        <p className={styles.quantity}>Quantity: {item.quantity}</p>

        {item.dateExpected &&
          <p className={styles.presaleNote}>Estimated to ship {new Date(item.dateExpected).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}</p>
        }

        {item.saleType === "exchangeOnly" &&
          <p className={styles.offerRed}>
            This item is eligible for exchange only.
          </p>
        }

        {item.saleType === "finalSale" &&
          <p className={styles.offerRed}>
            This item is final sale.
          </p>
        }

        {!isFavorite &&
          <button
            className={styles.favorite}
            onClick={moveItemToFavorites}
          >
            Move to Favorites
          </button>
        }

        <button
          className={styles.remove}
          onClick={removeItem}
        >
          Remove
        </button>
      </div>
      <p className={styles.price}>
        <Price
          price={(item.quantity * parseInt(item.price, 10))}
          compareAtPrice={(item.compareAtPrice) ?
            (item.quantity * parseInt(item.compareAtPrice, 10))
            : null
          }
          // intlPrices={item.intlPrices}
          quantity={item.quantity}
        />
      </p>
    </li>
  )
}

export default MiniCart
