import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import styles from "./heading.module.scss"
import settings from "./settings.module.scss"


const Heading = ({ tag, level, alignment, className, style, children }) => {
  const Tag = tag

  return (
    <Tag
      className={`${styles[`heading${level}`]} ${alignment ? settings[`textAlign${alignment}`] : ""} ${className || ""}`}
      style={style}
    >
      {children}
    </Tag>
  )
}

export default Heading

Heading.propTypes = {
  tag: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  alignment: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const query = graphql`
  fragment HeadingFragment on ContentfulHeading {
    heading
    textSize
    textAlignment
  }
`
