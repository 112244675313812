export default {

  addItem: (skus) => {
    // if (appState.signedIn) {
    //   return this._sendRequest("add", {i: skus})
    // } else {
      if (typeof window === "undefined" || !window.localStorage)
        return
      let localList = JSON.parse(localStorage.getItem("tm_wishlist")) || []
      const skuList = skus.split(",")
      let newLocalList = localList.concat(skuList)
      newLocalList = [...new Set(newLocalList)]; // retain only unique values
      localStorage.setItem("tm_wishlist", JSON.stringify(newLocalList))
      return new Promise((resolve, reject) => {
        resolve({added: skuList})
      });
    // }
  },

  removeItem: (sku) => {
    // if (appState.signedIn) {
    //   return this._sendRequest("remove", {i: sku})
    // } else {
      if (typeof window === "undefined" || !window.localStorage)
        return
      let localList = JSON.parse(localStorage.getItem("tm_wishlist")) || []
      localList = localList.filter(product => product !== sku)
      localStorage.setItem("tm_wishlist", JSON.stringify(localList))
      return new Promise((resolve, reject) => {
        resolve("Item Removed")
      });
    // }
  },

  getListSkus: () => {
    // if (this.signedIn) {
    //   return this._sendRequest("get")
    // } else {
      if (typeof window === "undefined" || !window.localStorage)
        return new Promise((resolve, reject) => {
          resolve([])
        })
      let localList = JSON.parse(localStorage.getItem("tm_wishlist")) || []
      return new Promise((resolve, reject) => {
        resolve(localList)
      })
      // return localList
    // }
  },

  getListProducts: () => {
    // if (this.signedIn) {
    //   return this._sendRequest("list")
    // } else {
      const localList = JSON.parse(localStorage.getItem("tm_wishlist")) || []
      return _sendRequest("products", {i: localList.join()})
    // }
  },
}


const baseUri = "https://l.tamaramellon.com/api/v1.1/wishlist"

const _sendRequest = (type, data) => {

  const url = new URL(`${baseUri}/${type}`)
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  }

  let params = {}
  // if (signedIn) {
  //   params = {
  //     c: this.apiEmail,
  //     h: this.apiId,
  //   }
  // }
  params = Object.assign({}, params, data)

  url.search = new URLSearchParams(params)

  function ApiError(message, data, status) {
    let response = null
    let isObject = false
    try {
      response = JSON.parse(data)
      isObject = true
    } catch (e) {
      response = data
    }
    
    this.response = response
    this.message = message
    this.status = status
    this.customToString = function () {
      return `${ this.message }\nResponse:\n${ isObject ? JSON.stringify(this.response, null, 2) : this.response }`
    };
  }

  let response = null
  return fetch(url, options)
    .then(responseObject => {
      response = responseObject
      return response.json()
    })
    .then(parsedResponse => {
      return parsedResponse
    })
    .catch(error => {
      if (response) {
        throw new ApiError(`Request failed with status ${ response.status }.`, error, response.status)
      } else {
        throw new ApiError(error.customToString(), null, "REQUEST_FAILED")
      }
    });
}
