import React from "react"
import PropTypes from "prop-types"
import qs from "qs"

import { AppProvider } from "./Context"
import Header from "./Header"
import Main from "./Main"
import Footer from "./Footer"
import MiniCart from "./MiniCart"
// import EmailCaptureModal from "./EmailCaptureModal"
import Chat from "./Chat"
import Loop from "./Loop"
import ThirdParty from "./ThirdParty"

import "./layout.scss"
import "swiper/css/swiper.css"

import { cookie } from "../helpers"

const Layout = ({ children, location, navigate }) => {

  // Capture utm params
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  if (typeof sessionStorage !== "undefined") {
    if (parsedQuery["utm_source"])
      sessionStorage.setItem("utm_source", parsedQuery["utm_source"])
    if (parsedQuery["utm_medium"])
      sessionStorage.setItem("utm_medium", parsedQuery["utm_medium"])
  }

  if (parsedQuery["PC"] || parsedQuery["pc"])
    cookie.set("pc", parsedQuery["PC"] || parsedQuery["pc"], null, location.hostname.replace("www.",""))

  return (
    <AppProvider>
      <Header
        location={location}
        navigate={navigate}
      />
      <Main
        location={location}
      >
        {children}
      </Main>
      <Footer
        location={location}
      />
      <MiniCart />
      {/*<EmailCaptureModal*/}
      {/*  location={location}*/}
      {/*/>*/}
      <Loop
        location={location}
      />
      {location.pathname !== "/discover/" &&
        <Chat
          location={location}
        />
      }
      <ThirdParty />
    </AppProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
