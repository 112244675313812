import React, { useState } from "react"
import axios from "axios"
import qs from "qs"
import { Link } from "gatsby"

import RichText from "./RichText"

import styles from "./emailCapture.module.scss"
import buttonStyles from "./button.module.scss"
import { cookie, trackEvent } from "../helpers"


const EmailCapture = props => {
  const {
    heading,
    text,
    submitText,
    disclaimerText,
    confirmationHeading,
    confirmationText,
    confirmationCta,
    listId,
    source,
    formName,
    captureSource,
    captureTrafficSource,
    captureLocation,
    offerDiscountCode,
    welcomeSeries,
    location,
    closeModal,
  } = props

  const confirmationTexts = confirmationText.split("<discount>")

  const [input, setInput] = useState("")
  const [phoneInput, setPhoneInput] = useState("")
  const [success, setSuccess] = useState(false)
  const [phoneSuccess, setPhoneSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(null)
  const [discountCode, setDiscountCode] = useState(null)

  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })

  const submitInput = e => {
    e.preventDefault()

    axios({
      url: "https://l.tamaramellon.com/api/email/subscribe",
      method: "post",
      data: {
        "g": listId,
        "email": input,
        "$timezone_offset": (new Date()).getTimezoneOffset()/-60,
        "$fields": "Newsletter_Signup_Location, form_name, capture_consent, capture_source, capture_trafficsource, capture_location, capture_medium, capture_campaign, welcome_series",
        "Newsletter_Signup_Location": source,
        "form_name": formName,
        "capture_consent": "email, web, directmail",
        "capture_source": captureSource || parsedQuery["utm_source"] || "",
        "capture_trafficsource": captureTrafficSource || "",
        "capture_location": captureLocation || "site",
        "capture_medium": parsedQuery["utm_medium"] || "",
        "capture_campaign": parsedQuery["utm_campaign"] || "",
        "discount_code": (offerDiscountCode) ? 1 : 0,
        "welcome_series": (welcomeSeries) ? "Y" : "N",
      },
    }).then(({ data }) => {
      switch(data.userStatus) {
        case "sucessfully_subscribed":
          if (data.discount_code) {
            setDiscountCode(data.discount_code)
            cookie.set("pc", data.discount_code, null, location.hostname.replace("www.",""))
          }
          setSuccess(true)
          subscribeAnalytics()
          break
        case "already_subscribed":
          setErrorMessage("You’re already signed up.")
          break
        default:
          setErrorMessage("Something happened. Please try again.")
          break
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const submitPhoneInput = e => {
    e.preventDefault()

    axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "post",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
      },
      data: qs.stringify({
        "g": "Ujd5mS",
        "$fields": "$phone_number, sms_consent",
        "email": input,
        "$phone_number": `+1${phoneInput}`,
        "sms_consent": true,
      }),
    }).then(({ data } ) => {
      console.log(data)
      if (data.success)
        setPhoneSuccess(true)
      else
        setPhoneErrorMessage("Something happened. Please try again.")
    }).catch(error => {
      console.log(error)
    })
  }
  
  const subscribeAnalytics = () => {
    if (window && window.hasOwnProperty("fbq"))
      window.fbq("track", "Lead")

    if (window && window.hasOwnProperty("ga"))
      trackEvent("Newsletter", "Subscribed", source)

    if (window && window.hasOwnProperty("heap") && input.includes("@"))
      window.heap.identify(input)

    if (window && window.hasOwnProperty("custoraTrack") && input.includes("@"))
      window.custoraTrack.identify(input, "")

    window.dataLayer.push({"event":"newsletter_signup"})
  }
  
  const handleNoThanks = () => {
    closeModal()
    trackEvent("Email Capture", "Close", "No Thanks")
  }

  return(
    <>
      {phoneSuccess || (success && source !== "popup") ?
        <div className={styles.successContainer}>
          {confirmationHeading &&
          <h2 className={styles.heading}>
            {confirmationHeading.heading}
          </h2>
          }
          <p className={styles.success}>
            {offerDiscountCode ?
              <>
                <span dangerouslySetInnerHTML={{ __html: confirmationTexts[0] }} />
                <strong className="code">{discountCode}</strong>
                <span dangerouslySetInnerHTML={{ __html: confirmationTexts[1] }} />
              </>
              : confirmationTexts[0]
            }
          </p>
          {confirmationCta &&
          <Link
            className={buttonStyles.btnDark}
            to={confirmationCta.url}
            onClick={() => closeModal()}
          >
            {confirmationCta.text}
          </Link>
          }
        </div>
      : success && source === "popup" ?
        <form
          className={styles.form}
          onSubmit={submitPhoneInput}
        >
          <p className={styles.mobileMessage}>
            Also — Give us your digits to receive early access to sales, product drops, and exclusive content.
          </p>
          <input
            type="phone"
            placeholder="Phone"
            className={styles.email}
            value={phoneInput}
            onChange={e => setPhoneInput(e.target.value)}
            required
          />
          <input
            type="submit"
            value="Text with TM"
            className={styles.submit}
          />
          {phoneErrorMessage &&
          <p className={styles.error}>
            {phoneErrorMessage}
          </p>
          }
          <div className={styles.disclaimer}>
            By signing up you agree to receive marketing text message from Tamara Mellon. Message and data rates may apply. Reply HELP for help or STOP to cancel. View our <Link to="/pages/privacy-policy">Privacy Policy</Link> and <Link to="/pages/terms-conditions">Terms of Service</Link>.
          </div>
        </form>
      :
        <form
          className={styles.form}
          onSubmit={submitInput}
        >
          {heading &&
            <h2 className={styles.heading}>
              {heading.heading}
            </h2>
          }
          {text &&
            <div className={styles.text}>
              <RichText
                json={text.json}
              />
            </div>
          }
          <input
            type="email"
            placeholder="Email"
            className={styles.email}
            value={input}
            onChange={e => setInput(e.target.value)}
            required
          />
          <input
            type="submit"
            value={submitText}
            className={styles.submit}
          />
          {errorMessage &&
            <p className={styles.error}>
              {errorMessage}
            </p>
          }
          {disclaimerText &&
            <div className={styles.disclaimer}>
              <RichText
                json={disclaimerText.json}
              />
            </div>
          }
          {closeModal &&
            <button
              className={styles.noThanksBtn}
              onClick={handleNoThanks}
            >No Thanks</button>
          }
        </form>
      }
    </>
  )
}

export default EmailCapture
