import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import styles from "./image.module.scss"
import { graphql } from "gatsby"


const Image = ({ image, altText, options, className, customStyles, fadeIn }) => {

  if (!image)
    return (
      <img src="" alt="" />
    )

  if (options && options.sizing === "art-directed") {

    if (!image.desktopImage.fluid && image.desktopImage.file.contentType === "image/svg+xml") {
      return (
        <>
          {!image.mobileImage &&
            <img
              src={image.desktopImage.file.url}
              alt={altText || image.desktopImage.description}
              className={className}
            />
          }
          {image.mobileImage &&
            <picture
              className={className}
            >
              <source
                media="(max-width: 767px)"
                srcSet={image.mobileImage.file.url}
              />
              <source
                media="(min-width: 768px)"
                srcSet={image.desktopImage.file.url}
              />
              <img
                src={image.desktopImage.file.url}
                alt={altText}
              />
            </picture>
          }
        </>
      )
    }

    const imgSources = [
      image.mobileImage.fluid,
      {
        ...image.desktopImage.fluid,
        media: `(min-width: ${options.breakpoint || 768}px)`,
      },
    ]

    return (
      <Img
        fluid={imgSources}
        alt={image.altText}
        className={className}
        style={customStyles}
        imgStyle={{ objectPosition: options.alignment }}
      />
    )
  }

  if (options && options.sizing === "plp" && image.desktopImage && image.mobileImage) {
    const desktopImage = image.desktopImage.replace(/(\.jpg)/i, "_1000x.progressive$1")
    const mobileImage = image.mobileImage.replace(/(\.jpg)/i, "_640x.progressive$1")
    return (
      <picture
        className={className}
      >
        <source
          srcSet={desktopImage}
          media="(min-width: 768px)"
        />
        <source
          srcSet={mobileImage}
          media="(max-width: 767px)"
        />
        <img
          src={mobileImage}
          alt={altText}
        />
      </picture>
    )
  }

  if (image.desktopImage && image.mobileImage) {
    return (
      <picture
        className={className}
      >
        <source
          srcSet={image.desktopImage}
          media="(min-width: 768px)"
        />
        <source
          srcSet={image.mobileImage}
          media="(max-width: 767px)"
        />
        <img
          src={image.mobileImage}
          alt={altText}
        />
      </picture>
    )
  }

  if (image.desktopImage && image.desktopImage.fluid)
    return (
      <Img
        fluid={image.desktopImage.fluid}
        alt={image.altText}
        className={className || styles.image}
        fadeIn={fadeIn}
      />
    )

  if (image.fluid && image.fluid.src)
    return (
      <Img
        fluid={image.fluid}
        alt={altText || image.description}
        className={className || styles.image}
        fadeIn={fadeIn}
      />
    )

  if (image.file && image.file.url)
    image = image.file.url

  if (options && options.origin === "shopify") {
    if (options.width || options.height)
      image = image.replace(/(\.jpg)/i, `_${options.width || ""}x${options.height || ""}$1`)
    image = image.replace(/(\.jpg)/i, ".progressive$1")
  }

  return (
    <img
      src={image}
      alt={altText || image.description}
      className={className || styles.image}
    />
  )
}

Image.propTypes = {
  // image: PropTypes.object.isRequired,
  altText: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string,
  customStyles: PropTypes.object,
}

export default Image



export const query = graphql`
    fragment VideoFragment on ContentfulVideo {
        desktopVideo {
            file {
                url
            }
        }
        mobileVideo {
            file {
                url
            }
        }
        altText
    }
`
