import React, { useState, useEffect, useContext } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
// import Swiper from "react-id-swiper"

import Image from "./Image"
// import Search from "./Search"
import { AppContext } from "./Context"

import styles from "./header.module.scss"


const Header = ({ location, navigate }) => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulMainHeader(componentName: {eq: "Site Header SS24"}) {
        logo {
          ...LinkFragment
        }
        discoveryLinks {
          ...LinkFragment
          ... on ContentfulLinkGroup {
            heading {
              ...HeadingFragment
            }
            links {
              ...LinkFragment
              ... on ContentfulLinkGroup {
                heading {
                    ...HeadingFragment
                }
                links {
                    ...LinkFragment
                }
                url
              }
            }
            url
          }
        }
        transparent
      }
    }
  `)

  const appState = useContext(AppContext)

  const {
    logo,
    discoveryLinks,
    // transparent,
  } = data.contentfulMainHeader

  const transparent = false

  //Handle hover for subnav links on desktop
  const [linksCloseTimeout, setLinksCloseTimeout] = useState(null)
  const [subnav, setSubnav] = useState(null)

  // const swiperParams = {
  //   containerClass: `${styles.swiperContainer}`,
  //   direction: "vertical",
  //   loop: true,
  //   slidesPerView: 1,
  //   autoplay: {
  //     delay: 5000,
  //   },
  //   height: 50,
  //   allowTouchMove: false,
  // }

  // const handleCurrencyChange = e => {
  //   appState.set({currency: e.target.value})
  //   if (typeof localStorage !== "undefined")
  //     localStorage.setItem("currency3", e.target.value)
  // }

  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setClient(true)
  }, [])

  return (
    <header
      className={[
        (appState.navOpen) ? styles.headerNavOpen : styles.header,
        (appState.subnavOpen) ? styles.headerSubNavOpen : "",
        (appState.searchOpen) ? styles.headerSearchOpen : "",
        (appState.bannerOpen) ? styles.hasBanner : "",
        (location.pathname.includes("/cart")) ? styles.forCart : "",
        (location.pathname === "/" && transparent) ? styles.transparent : "",
      ].join(" ")}
    >
      {!appState.navOpen && appState.bannerOpen &&
        <div className={styles.banner}>
          SHOP 85% OFF SITE WIDE.<br /><span>Discount automatically applied at checkout. FINAL SALE only.</span>
          <button
            className={styles.bannerCloseBtn}
            onClick={() => appState.set({ bannerOpen: false })}
          >
            Close Banner
          </button>
        </div>
      }
      <div className={styles.headerWrapper}>
        <Link
          to={logo.url}
          className={styles.logo}
        >
          <Image
            image={logo.image}
            altText={logo.text}
          />
        </Link>

        {!location.pathname.includes("/cart") &&
          <>
            <button
              className={(appState.navOpen || appState.searchOpen) ? styles.linkMenuClose : styles.linkMenu}
              onClick={e => {
                if (appState.searchOpen)
                  appState.set({ searchOpen: false })
                else
                  appState.set({ navOpen: !appState.navOpen })
              }}
            >
              Menu
            </button>

            <nav className={styles.nav}>

              <ul
                className={styles.linksDiscovery}
              >
                {discoveryLinks.map((link, i) =>
                  <li
                    key={i}
                    className={styles.linkItem}
                  >
                    {link.links && link.heading.heading === "Shop" && typeof window !== "undefined" && window.outerWidth < 1024 ?
                      <>
                        {link.links.map((subLink, j) =>
                          <>
                            {subLink.links &&
                              <Subnav
                                link={subLink}
                                linksCloseTimeout={linksCloseTimeout}
                                setLinksCloseTimeout={setLinksCloseTimeout}
                                subnav={subnav}
                                setSubnav={setSubnav}
                                i={i+j+5} // TODO: Figure out a better way to calculate non-conflicting index
                                navigate={navigate}
                              />
                            }
                          </>
                        )}
                      </>
                    : link.links ?
                      <Subnav
                        link={link}
                        linksCloseTimeout={linksCloseTimeout}
                        setLinksCloseTimeout={setLinksCloseTimeout}
                        subnav={subnav}
                        setSubnav={setSubnav}
                        i={i}
                        navigate={navigate}
                      />
                    :
                      <Link
                        to={`${link.url}?origin=nav`}
                        className={`${styles.link} ${styles[`link${link.text.replace(/\s/g, "")}`]} ${(link.highlight) ? styles.linkHighlight : ""}`}
                        onClick={e => appState.set({ navOpen: false })}
                      >
                        {link.image ?
                          <Image
                            image={link.image}
                            altText={link.text}
                          />
                          :
                          link.text
                        }
                      </Link>
                    }
                  </li>
                )}
              </ul>

              <ul className={styles.linksMisc} key={key}>
                <li className={styles.linkItemSearch}>
                  <button onClick={e => appState.set({searchOpen: !appState.searchOpen })} className={styles.link}>Search</button>
                </li>
                {/* <li className={styles.linkItemAccount}> */}
                {/*   <select */}
                {/*     className={styles.link} */}
                {/*     value={appState.currency} */}
                {/*     onChange={handleCurrencyChange} */}
                {/*     onBlur={handleCurrencyChange} */}
                {/*   > */}
                {/*     <option value="USD">USD $</option> */}
                {/*     <option value="AED">AED د.إ</option> */}
                {/*     <option value="CAD">CAD $</option> */}
                {/*     <option value="EUR">EUR €</option> */}
                {/*     <option value="GBP">GBP £</option> */}
                {/*   </select> */}
                {/* </li> */}
                <li className={styles.linkItemFavorites}>
                  <Link
                    to="/pages/favorites?origin=nav"
                    className={styles.link}
                    onClick={e => appState.set({ navOpen: false })}
                  >
                    Favorites ({(appState.favoritesSkus) ? appState.favoritesSkus.length : "0"})
                  </Link>
                </li>
                <li className={styles.linkItemCart}>
                  <button onClick={e => appState.set({miniCartOpen: true})} className={styles.link}>
                    <span className={styles.cartSpelled}>Cart ({appState.cartCount})</span>
                    <span className={styles.count}>{appState.cartCount}</span>
                  </button>
                </li>
              </ul>
            </nav>

            <button onClick={e => appState.set({miniCartOpen: true})} className={styles.linkItemCart}>
              <span className={styles.cartSpelled}>Cart ({appState.cartCount})</span>
              <span className={styles.count}>{appState.cartCount}</span>
            </button>
          </>
        }
      </div>
      {appState.searchOpen &&
        <div className={styles.search}>
          {/* Mobile search field */}
          <form
            action=""
            className={styles.searchForm}
            autoComplete="off"
            onSubmit={e => {
              const redirectQueries = [
                "sandals",
                "flats",
                "boots",
                "sneakers",
                "pumps",
                "bags",
              ]
              e.preventDefault()
              if (redirectQueries.includes(appState.searchTerm))
                navigate(`/collections/${appState.searchTerm}`)
              else if (appState.searchTerm.includes("boot"))
                navigate(`/collections/boots`)
              else if (appState.searchTerm.includes("heel"))
                navigate(`/collections/pumps`)
              else if (appState.searchTerm.includes("pillow top"))
                navigate(`/collections/pt`)
              else if (appState.searchTerm.includes("cloud cushion"))
                navigate(`/collections/cloud-cushion`)
              else if (appState.searchTerm.includes("return"))
                navigate(`/pages/our-info?origin=search#returns`)
              else if (appState.searchTerm.includes("irina") || appState.searchTerm.includes("irina shayk") || appState.searchTerm.includes("isxtm"))
                navigate(`/pages/irinashayk-ss21`)
              else if (appState.searchTerm.includes("galactic"))
                navigate(`/products/stellar-velvet-crystals-sandals`)
              else
                navigate(`/search?query=${encodeURIComponent(appState.searchTerm)}`)
              appState.set({
                searchOpen: false,
                navOpen: false,
              })
              document.activeElement.blur()
            }}
          >
            <input
              type="search"
              placeholder="Search Here"
              className={styles.searchInput}
              value={appState.searchTerm}
              onChange={e => {
                appState.set({ searchTerm: e.currentTarget.value })
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <input
              type="submit"
              className={styles.searchSubmit}
            />
          </form>
          <button
            className={styles.searchCloseBtn}
            onClick={e => appState.set({ searchOpen: false })}
            aria-label="Close Search"
          />
        </div>
      }
    </header>
  )
}

export default Header

const Subnav = ({ link, linksCloseTimeout, setLinksCloseTimeout, subnav, setSubnav, i, navigate }) => {

  const appState = useContext(AppContext)

  const handleLinksEnter = e => {
    if (window.outerWidth >= 1024) {
      clearTimeout(linksCloseTimeout)
      appState.set({ subnavOpen: true })
      let tempState = {}
      tempState[`subnav${i}Open`] = true
      if (`subnav${i}Open` !== subnav)
        tempState[subnav] = false
      appState.set(tempState)
      setSubnav(`subnav${i}Open`)
    }
  }

  const handleLinksLeave = e => {
    if (window.outerWidth >= 1024) {
      setLinksCloseTimeout(setTimeout(() => {
        appState.set({ subnavOpen: false })
        let tempState = {}
        tempState[`subnav${i}Open`] = false
        appState.set(tempState)
      }, 1000))
    }
  }

  const handleLinksClick = e => {
    if (window.outerWidth < 1024) {
      appState.set({ subnavOpen: true })
      let tempState = {}
      tempState[`subnav${i}Open`] = true
      appState.set(tempState)
    } else if (link.url) {
      navigate(`${link.url}?origin=nav`)
    }
  }

  const handleLinksCloseClick = e => {
    if (window.outerWidth < 1024) {
      appState.set({ subnavOpen: false })
      setTimeout(() => {
        let tempState = {}
        tempState[`subnav${i}Open`] = false
        appState.set(tempState)
      }, 250)
    }
  }

  return(
    <div
      role="presentation"
      onMouseEnter={handleLinksEnter}
      onMouseLeave={handleLinksLeave}
    >
      <button
        className={`${styles.link} ${(appState[`subnav${i}Open`]) ? styles.btnHoverOnlyOpen : styles.btnHoverOnly}`}
        onClick={handleLinksClick}
      >
        {link.heading.heading}
      </button>
      <ul
        className={`${(appState[`subnav${i}Open`]) ? styles.linksSubOpen : styles.linksSub} ${styles[`linksSub${link.heading.heading}`]}`}
      >
        <li className={styles.linksBackButton}>
          <button
            onClick={handleLinksCloseClick}
            aria-label="Back"
          />
        </li>
        {link.links.map((subLink, j) =>
          <li key={j} className={styles.linkItem}>
            {subLink.links ?
              <>
                <h2 className={styles.subLinkHeading}>
                  {subLink.url ?
                    <Link
                      to={`${subLink.url}?origin=nav`}
                      onClick={e => {
                        appState.set({
                          navOpen: false,
                          subnavOpen: false,
                        })
                        let tempState = {}
                        tempState[`subnav${i}Open`] = false
                        appState.set(tempState)
                      }}
                    >
                      {subLink.heading.heading}
                    </Link>
                    :
                    subLink.heading.heading
                  }
                </h2>
                <ul className={styles.linksSubSub}>
                  {subLink.links.map((subSubLink, k) =>
                    <Link
                      to={`${subSubLink.url}?origin=nav`}
                      className={`${styles.link} ${styles.subLink} ${styles[`link${subSubLink.text.replace(/\s/g, "")}`]}`}
                      onClick={e => {
                        appState.set({
                          navOpen: false,
                          subnavOpen: false,
                        })
                        let tempState = {}
                        tempState[`subnav${i}Open`] = false
                        appState.set(tempState)
                      }}
                      key={k}
                    >
                      {subSubLink.image ?
                        <Image
                          image={subSubLink.image}
                          altText={subSubLink.text}
                        />
                        :
                        subSubLink.text
                      }
                    </Link>
                  )}
                </ul>
              </>
              :
              <Link
                to={`${subLink.url}?origin=nav`}
                className={`${styles.link} ${styles.subLink} ${styles[`link${subLink.text.replace(/\s/g, "")}`]}`}
                onClick={e => {
                  appState.set({
                    navOpen: false,
                    subnavOpen: false,
                  })
                  let tempState = {}
                  tempState[`subnav${i}Open`] = false
                  appState.set(tempState)
                }}
              >
                {subLink.image ?
                  <Image
                    image={subLink.image}
                    altText={subLink.text}
                  />
                  :
                  subLink.text
                }
              </Link>
            }
          </li>
        )}
      </ul>
    </div>
  )
}

export const query = graphql`
  fragment LinkFragment on ContentfulLink {
    text
    url
    image {
      file {
        url
        contentType
      }
    }
    highlight
  }
`