// local storage cart functionality

// TODO: During sales, change tm_currencyCart to tm_saleCart

const cart = {

  addItem: item => {
    if (typeof localStorage !== 'undefined') {
      const cartItems = cart.getCart()
      cartItems.push(item)
      localStorage.setItem('tm_currencyCart1', JSON.stringify(cartItems))
      cartAnalytics(item, "add_to_cart", 1)
    }
  },

  removeItem: item => {
    if (typeof localStorage !== 'undefined') {
      const cartItems = cart.getCart()
      const itemIndexInCart = getItemIndexInCart(cartItems, item)
      cartItems.splice(itemIndexInCart, 1)
      localStorage.setItem('tm_currencyCart1', JSON.stringify(cartItems))
      cartAnalytics(item, "remove_from_cart", 1)
    }
  },

  incrementQuantity: (item, amount) => {
    if (typeof localStorage !== 'undefined') {
      const cartItems = cart.getCart()
      const itemIndexInCart = getItemIndexInCart(cartItems, item)
      cartItems[itemIndexInCart].quantity += amount
      localStorage.setItem('tm_currencyCart1', JSON.stringify(cartItems))
      cartAnalytics(item, "add_to_cart", amount)
    }
  },

  decrementQuantity: (item, amount) => {
    if (typeof localStorage !== 'undefined') {
      const cartItems = cart.getCart()
      const itemIndexInCart = getItemIndexInCart(cartItems, item)
      cartItems[itemIndexInCart].quantity -= amount
      localStorage.setItem('tm_currencyCart1', JSON.stringify(cartItems))
      cartAnalytics(item, "remove_from_cart", amount)
    }
  },

  getCart: () => {
    if (typeof localStorage !== 'undefined')
      return JSON.parse(localStorage.getItem('tm_currencyCart1')) || []
    else
      return []
  },

  getCartCount: () => {
    return cart.getCart().reduce((acc, item) => (acc + item.quantity), 0)
  },

  clearCart: () => {

  },
}

export default cart

const getItemIndexInCart = (cartItems, item) => {
  return cartItems.findIndex(i => i.variantId === item.variantId)
}

const cartAnalytics = (item, action, quantity) => {
  if (window.hasOwnProperty("gtag"))
    window.gtag("event", action, {
      "items": [{
        "id": `${item.productId}`,
        "name": item.title,
        "brand": "Tamara Mellon Brand",
        "variant": `${item.color} - Size ${item.size}`,
        "quantity": quantity,
        "price": `${item.price}.00`,
      }],
    })
}
