import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import aa from "search-insights"

const ThirdParty = () => {

  aa("init", {
    appId: "WN0K2FPQPE",
    apiKey: "8e5ab94e5bb026fd40906a5712e6d546"
  })

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "442505702768387",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      })
    }
  })

  return(
    <>
      <Helmet>
        {/* TODO: Create data layer */}
        <script>{`
          window.dataLayer = window.dataLayer || [];
        `}</script>

        {/* Set user email from _ke query param */}
        <script>{`
          window._keKey = '_ke';
          window._keRegex = new RegExp( '[?&]' + window._keKey + '=([^&#]*)', 'i' );
          window._keUrl = decodeURIComponent(window.location.href);
          window._keValue = window._keRegex.exec(window._keUrl);
          if (window._keValue) {
            const _keSplit = _ke.split('=')[0];
            const _keDecoded = atob(_keSplit);
            const _keParsed = JSON.parse(_keDecoded);
            window._keEmail = _keParsed.kl_email;
          }
        `}</script>

        {/* LivePerson */}
        <script async>{`
          window.lpTag=window.lpTag||{},"undefined"==typeof window.lpTag._tagCount?(window.lpTag={site:'7481882'||"",section:lpTag.section||"",tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:"1.8.0",_tagCount:1,protocol:"https:",events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0==e?(this._defB=this._defB||[],this._defB.push(t)):1==e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+"//"+(this.ovr&&this.ovr.domain?this.ovr.domain:"lptag.liveperson.net")+"/tag/tag.js?site="+this.site);var a=document.createElement("script");a.setAttribute("charset",e?e:"UTF-8"),i&&a.setAttribute("id",i),a.setAttribute("src",n),document.getElementsByTagName("head").item(0).appendChild(a)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent("onload",function(){t._domReady("domReady")}):(window.addEventListener("DOMContentLoaded",function(){t._domReady("contReady")},!1),window.addEventListener("load",function(){t._domReady("domReady")},!1)),"undefined"==typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger("LPT","DOM_READY",{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;
            `}</script>

        {/* Custora */}
        <script>{`
          window.custoraTrack=window.custoraTrack||[],custoraTrack.load=function(t,a){var r,c,o;window.custoraTrack.appid=t,window.custoraTrack.config=a,r=document.createElement("script"),r.type="text/javascript",r.async=!0,r.src=("https:"===document.location.protocol?"https:":"http:")+"//track.custora.com/pixel.js",c=document.getElementsByTagName("script")[0],c.parentNode.insertBefore(r,c),o=function(t){return function(){custoraTrack.push([t].concat(Array.prototype.slice.call(arguments,0)))}},custoraTrack.track=o("track"),custoraTrack.identify=o("identify"),custoraTrack.addToCart =o("addToCart"),custoraTrack.productView=o("productView"),custoraTrack.searchView=o("searchView"),custoraTrack.addToWishList=o("addToWishList"),custoraTrack.createAccount=o ("createAccount")},custoraTrack.load("14f8c97e83aeb68");
          if (window._keEmail) custoraTrack.identify(window._keEmail, '');
        `}</script>

        {/* BounceX */}
        <script>{`
          (function(d) {
            var e = d.createElement('script');
            e.src = d.location.protocol + '//tag.bounceexchange.com/3888/i.js';
            e.async = true;
            d.getElementsByTagName("head")[0].appendChild(e);
          }(document));
        `}</script>

        {/* Heap */}
        <script>{`
          if (window.hasOwnProperty('heap') && window._keEmail) heap.identify(window._keEmail);
        `}</script>

        {/* Klaviyo */}
        <script>{`
          var _learnq = _learnq || [];
          _learnq.push(['account', 'cfVfSd']);
          if (window._keEmail) _learnq.push(['identify', {'$email' : window._keEmail}]);
          (function() {
            var b = document.createElement('script'); b.type = 'text/javascript'; b.async = true;
            b.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'a.klaviyo.com/media/js/analytics/analytics.js';
            var a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(b, a);
          })();
        `}</script>

        {/* Criteo */}
        <script>{`
          (function(d) {
            var e = d.createElement('script');
            e.src = d.location.protocol + '//static.criteo.net/js/ld/ld.js';
            e.async = true;
            d.getElementsByTagName("head")[0].appendChild(e);
          }(document));
          var site_type = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
          window.criteo_q = window.criteo_q || [];
          window.criteo_q.push(
            { event: "setAccount", account: 36635 },
            { event: "setSiteType", type: site_type}
          );
        `}</script>

        {/* Facebook */}
        {/*<script>{`*/}
        {/*  !function(f,b,e,v,n,t,s)*/}
        {/*  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?*/}
        {/*    n.callMethod.apply(n,arguments):n.queue.push(arguments)};*/}
        {/*    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';*/}
        {/*    n.queue=[];t=b.createElement(e);t.async=!0;*/}
        {/*    t.src=v;s=b.getElementsByTagName(e)[0];*/}
        {/*    s.parentNode.insertBefore(t,s)}(window, document,'script',*/}
        {/*  'https://connect.facebook.net/en_US/fbevents.js');*/}
        {/*  */}
        {/*  var fbqTimer = setInterval(function() {*/}
        {/*    if (window && window.hasOwnProperty('fbq')) {*/}
        {/*      clearInterval(fbqTimer);*/}
        {/*      fbq('init', '1633949316856164');*/}
        {/*      fbq('track', 'PageView');*/}
        {/*    }*/}
        {/*  }, 50);*/}
        {/*`}</script>*/}

        {/* LiveSession.io */}
        <script>{`
          !function(w, d, t, u) { if (w.__ls) return; var f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)}; if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0"; var ls = d.createElement(t); ls.async = true; ls.src = u; var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s); }(window, document, 'script', ('https:' == window.location.protocol ? 'https://' : 'http://') + 'cdn.livesession.io/track.js'); __ls("init", "6d61cc31.9ee51461"); __ls("newPageView")`}
        </script>

        {/* Accessibe */}
        <script>{`
          (function(){
            var s    = document.createElement('script');
            var h    = document.querySelector('head') || document.body;
            s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async  = true;
            s.onload = function(){
              acsbJS.init({
                statementLink    : '',
                footerHtml       : '',
                hideMobile       : true,
                hideTrigger      : true,
                disableBgProcess : false,
                language         : 'en',
                position         : 'right',
                leadColor        : '#000000',
                triggerColor     : '#000000',
                triggerRadius    : '50%',
                triggerPositionX : 'right',
                triggerPositionY : 'bottom',
                triggerIcon      : 'people',
                triggerSize      : 'medium',
                triggerOffsetX   : 20,
                triggerOffsetY   : 20,
                mobile           : {
                  triggerSize      : 'small',
                  triggerPositionX : 'right',
                  triggerPositionY : 'center',
                  triggerOffsetX   : 10,
                  triggerOffsetY   : 0,
                  triggerRadius    : '50%'
                }
              });
            };
            h.appendChild(s);
          })();
        `}</script>

      </Helmet>

      {/* Facebook SDK */}
      {/* TODO: Figure out how to use sdk.js without 'init not called with valid version' error */}
      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/all.js" />
      <div id="fb-root" />
    </>
  )
}

export default ThirdParty
