import React from "react"
import { Link } from "gatsby"
// import PropTypes from "prop-types"

import Image from "./Image"

import styles from "./link.module.scss"


const LinkTM = ({ url, text, image, className, children }) => {

  const handleCalendlyClick = e => {
    window.Calendly.initPopupWidget({url: url})

    if (window.hasOwnProperty('fbq'))
      window.fbq('track', 'Calendly')
  }

  return (
    <>
      {url &&
        <>{(url.includes("calendly")) ?
          <button
            onClick={handleCalendlyClick}
            className={className || styles.link}
          >
            {text || children}
          </button>
          : (url.includes("http") || url.includes("mailto")) ?
          <a
            href={url}
            className={className || styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {image &&
            <Image
              image={image}
              altText={text}
            />
            }
            {!image &&
            (text || children)
            }
          </a>
          :
          <Link
            to={url}
            className={className || styles.link}
          >
            {image &&
            <Image
              image={image}
              altText={text}
            />
            }
            {!image &&
            (text || children)
            }
          </Link>
          }
        </>
      }
      {!url && image &&
        <Image
          image={image}
          altText={text}
        />
      }
      {!url && !image &&
        (text || children)
      }
    </>
  )
}

// LinkTM.propTypes = {
//   url: PropTypes.string.isRequired,
//   text: PropTypes.string.isRequired,
//   image: PropTypes.object,
//   className: PropTypes.string,
// }

export default LinkTM
