import React, { useContext }  from "react"

import { AppContext } from "./Context"
import styles from "./price.module.scss"

const Price = ({ price, compareAtPrice, intlPrices = [], quantity }) => {

  const appState = useContext(AppContext)

  if (appState.currency !== "USD") {
    if (appState.currency === "AED") {
      price = (parseInt(intlPrices[0].node.price.amount, 10) * (quantity || 1))
      if (intlPrices[0].node.compareAtPrice)
        compareAtPrice = (parseInt(intlPrices[0].node.compareAtPrice.amount, 10) * (quantity || 1))
    }
    if (appState.currency === "CAD") {
      price = (parseInt(intlPrices[1].node.price.amount, 10) * (quantity || 1))
      if (intlPrices[1].node.compareAtPrice)
        compareAtPrice = (parseInt(intlPrices[1].node.compareAtPrice.amount, 10) * (quantity || 1))
    }
    if (appState.currency === "EUR") {
      price = (parseInt(intlPrices[2].node.price.amount, 10) * (quantity || 1))
      if (intlPrices[2].node.compareAtPrice)
        compareAtPrice = (parseInt(intlPrices[2].node.compareAtPrice.amount, 10) * (quantity || 1))
    }
    if (appState.currency === "GBP") {
      price = (parseInt(Math.ceil(intlPrices[3].node.price.amount), 10) * (quantity || 1))
      if (intlPrices[3].node.compareAtPrice)
        compareAtPrice = (parseInt(Math.ceil(intlPrices[3].node.compareAtPrice.amount), 10) * (quantity || 1))
    }
  }

  const currencySymbol = (appState.currency === "EUR") ?
    "€"
    : (appState.currency === "GBP") ?
    "£"
    : (appState.currency === "AED") ?
    "د.إ"
    : (appState.currency === "CAD") ?
    "$"
    : "$"

  return (
    <>
      {compareAtPrice ? (
        <>
          <span className={styles.originalPrice}>
            <s>{currencySymbol}{compareAtPrice.toLocaleString()}</s>
          </span>
          <span className={styles.salePrice}>{currencySymbol}{price.toLocaleString()}</span>
        </>
      ) : (
        <>
          {currencySymbol}{price.toLocaleString()}
        </>
      )}
    </>
  )
}

export default Price
