import React from "react"
import cart from "../helpers/cart"
import favorites from "../helpers/favorites"


const initialState = {
  // device: typeof window !== "undefined" ? ( window.innerWidth < 768 ? "mobile" : "desktop" ) : "",
  cartCount: typeof window !== "undefined" ? cart.getCartCount() : "",
  // windowWidth: typeof window !== "undefined" ? window.innerWidth : undefined,
  navOpen: false,
  subnavOpen: false,
  searchOpen: false,
  selectedSize: typeof window !== "undefined" ? localStorage.getItem("selectedSize") : null,
  searchTerm: "",
  bannerOpen: false,
  isMobile: typeof navigator !== "undefined" && /Mobi|Android/i.test(navigator.userAgent),
  isApple: typeof navigator !== "undefined" && !!navigator.platform && /iPhone|iPad|Mac/i.test(navigator.platform),
  chatOpen: false,
  userToken: "userToken-" + Math.floor(Math.random() * 1000000),
  gridView: [4, 2],
  currency: typeof localStorage !== "undefined" ? localStorage.getItem("currency3") || "USD" : "USD",
  miniCartOpen: false,
  zoomedImages: true,
}

favorites.getListSkus()
  .then(skus => {
    initialState.favoritesSkus = skus
  })

const AppContext = React.createContext(initialState)
const Provider = AppContext.Provider
const AppConsumer = AppContext.Consumer

class AppProvider extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...initialState,
      set: this.setData,
    }
  }

  setData(newData) {
    this.setState({
      ...newData,
    })
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { AppConsumer, AppProvider, AppContext }
