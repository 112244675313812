import React, { useContext, useState } from "react"
// import PropTypes from "prop-types"

import Heading from "./Heading"
import Link from "./LinkTM"
import EmailCapture from "./EmailCapture"
import { AppContext } from "./Context"

import styles from "./footer.module.scss"
import headerStyles from "./header.module.scss"
import emailStyles from "./emailCapture.module.scss"
import { graphql, useStaticQuery } from "gatsby"


const Footer = ({ location, setFooterEl }) => {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulMainFooter(componentName: {eq: "Site Footer SS22"}) {
        linkGroups {
          heading {
            ...HeadingFragment
          }
          links {
            ...LinkFragment
          }
        }
        socialLinkGroup {
          heading {
            ...HeadingFragment
          }
          links {
            ...LinkFragment
          }
        }
        emailCaptureHeading
        emailCaptureSubmitText
        emailCaptureConfirmationText
      }
    }
  `)

  const {
    linkGroups,
    socialLinkGroup,
    emailCaptureHeading,
    emailCaptureSubmitText,
    emailCaptureConfirmationText,
  } = data.contentfulMainFooter

  const appState = useContext(AppContext)

  const [sectionOpen, setSectionOpen] = useState(null)

  const handleHeadingBtnClick = heading => {
    if (sectionOpen === heading)
      setSectionOpen(null)
    else
      setSectionOpen(heading)
  }

  return (
    <footer
      className={`
        ${(location.pathname === "/") ? headerStyles.footerContainer : ""}
        ${styles.container} ${(appState.navOpen) ? styles.navOpen : ""}
        ${emailStyles.footer}
      `}
    >
      {!location.pathname.includes("/cart") &&
        <>
          <div className={styles.footer1}>
            <section className={styles.emailCapture}>
              {emailCaptureHeading &&
                <Heading
                  tag="h4"
                  level="4"
                  className={styles.heading}
                >
                  {emailCaptureHeading}
                </Heading>
              }
              <EmailCapture
                submitText={emailCaptureSubmitText}
                confirmationText={emailCaptureConfirmationText}
                listId="UUpRVJ"
                source="footer"
                formName="Default"
                offerDiscountCode={false}
                welcomeSeries={true}
                location={location}
              />
            </section>
            <div className={styles.emptyGroup} />
            <section className={styles.contactGroup}>
              <Heading
                tag="h4"
                level="4"
                className={styles.heading}
              >
                Customer Service
              </Heading>
              <ul className={styles.linkList}>
                {/*<li className={styles.linkItem}>*/}
                {/*  <a href="tel:8664195500" className={styles.link}>(866) 419-5500</a>*/}
                {/*</li>*/}
                <li className={styles.linkItem}>
                  <a href="mailto:atyourservice@tamaramellon.com" className={styles.link}>atyourservice@tamaramellon.com</a>
                </li>
              </ul>
            </section>
            {socialLinkGroup &&
              <section className={(sectionOpen === "Follow") ? styles.socialGroupOpen : styles.socialGroup}>
                <Heading
                  tag="h4"
                  level="4"
                  className={styles.heading}
                >
                  {typeof window !== "undefined" && window.outerWidth < 1024 ?
                    <button className={styles.headingBtn} onClick={() => handleHeadingBtnClick("Follow")}>Follow</button>
                    :
                    "Follow"
                  }
                </Heading>
                <ul className={styles.linkList}>
                  {socialLinkGroup.links.map((link, index) => (
                    <li
                      key={index}
                      className={styles.linkItem}
                    >
                      <Link
                        url={link.url}
                        text={link.text}
                        className={styles.link}
                      />
                    </li>
                  ))}
                </ul>
              </section>
            }
          </div>
          <div className={styles.footer2}>
            {linkGroups.map((group, i) => (
              <section
                key={i}
                className={(sectionOpen === group.heading.heading) ? styles.linkGroupOpen : styles.linkGroup}
              >
                {group.heading &&
                  <Heading
                    tag="h4"
                    level="5"
                    className={styles.heading}
                  >
                    {typeof window !== "undefined" && window.outerWidth < 1024 ?
                      <button className={styles.headingBtn} onClick={() => handleHeadingBtnClick(group.heading.heading)}>{group.heading.heading}</button>
                    :
                      `${group.heading.heading}`
                    }
                  </Heading>
                }
                <ul className={styles.linkList}>
                  {group.links.map((link, j) => (
                    <li
                      key={j}
                      className={styles.linkItem}
                    >
                      {link.text === "Accessibility" ?
                        <button data-acsb-custom-trigger="true" className={styles.buttonLink}>Accessibility</button>
                        :
                        <Link
                          url={`${link.url.split("#")[0]}?origin=footer#${link.url.split("#")[1] || ""}`}
                          text={link.text}
                          className={styles.link}
                        />
                      }
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </>
      }
      <p className={styles.copyright}>
        &copy; Tamara Mellon 2024
      </p>
    </footer>
  )
}

export default Footer
